var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.lists)?_c('div',{staticClass:"product-list-container"},_vm._l((_vm.lists),function(list,index){return _c('router-link',{key:index,staticClass:"list__item scrollObj preload",class:{
      'double-shape': list.is_big === 1,
      hoverEffect: !_vm.$bowser.mobile
    },attrs:{"tag":"div","data-src":_vm._f("parseImage")(list.cover.normal),"to":{
      path: ("/product/" + (list.uuid))
    }}},[_c('div',{staticClass:"item__cover"},[_c('div',{staticClass:"cover__child img",style:({
          backgroundImage: ("url(" + (_vm.parseImage(list.cover.normal)) + ")")
        })}),_c('div',{staticClass:"cover__child img__hover",style:({
          backgroundImage: ("url(" + (_vm.parseImage(list.cover.hover)) + ")")
        })})]),_c('div',{staticClass:"item__info"},[_c('p',{staticClass:"info__name"},[_vm._v(_vm._s(list.category.name.cn)+" - "+_vm._s(list.name))]),_c('p',{staticClass:"info__price"},[_vm._v("￥"+_vm._s(list.price))])])])}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }