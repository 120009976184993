<template>
  <div class="product-list-container" v-if="lists">
    <router-link
      tag="div"
      class="list__item scrollObj preload"
      v-for="(list, index) in lists"
      :key="index"
      :class="{
        'double-shape': list.is_big === 1,
        hoverEffect: !$bowser.mobile
      }"
      :data-src="list.cover.normal | parseImage"
      :to="{
        path: `/product/${list.uuid}`
      }"
    >
      <div class="item__cover">
        <div
          class="cover__child img"
          :style="{
            backgroundImage: `url(${parseImage(list.cover.normal)})`
          }"
        ></div>
        <div
          class="cover__child img__hover"
          :style="{
            backgroundImage: `url(${parseImage(list.cover.hover)})`
          }"
        ></div>
      </div>
      <div class="item__info">
        <p class="info__name">{{ list.category.name.cn }} - {{ list.name }}</p>
        <p class="info__price">￥{{ list.price }}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "c_product-list",
  props: {
    lists: {
      type: Array,
      default: null
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    parseImage(url) {
      return this.$options.filters["parseImage"](url);
    }
  }
};
</script>

<style></style>
