<template>
  <div class="page-common-body product-list-page-container">
    <div class="plpc__inner">
      <div class="page__title">
        <div v-if="headerText.en">
          <p class="title__en">
            {{ headerText.en
            }}<span class="title__count"
              >(<label for="">{{ headerText.size }}</label
              >)</span
            >
          </p>
          <p class="title__cn">
            {{ keyword ? `搜索关键字:${keyword}` : headerText.cn }}
          </p>
        </div>
      </div>
      <scroll-load
        v-model="paginationLoading"
        @load="loadmore"
        :finished="paginationFinished"
        :none="totalCount === 0"
      >
        <product-list :lists="lists"></product-list>
      </scroll-load>
    </div>
  </div>
</template>

<script>
import productList from "../common/_product-list.vue";
import { imgPreload } from "@/assets/utils/common";
import { mapState, mapMutations } from "vuex";
import wxtool from "@/assets/utils/wx-tool.js";
export default {
  name: "page-product-list",
  components: {
    productList
  },
  props: {
    type: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      lists: [],
      page: 1,
      totalCount: null,
      paginationLoading: false,
      paginationFinished: false
    };
  },
  computed: {
    ...mapState("shop", ["types"]),
    typeId() {
      const typeId = Number(this.type);
      return typeId;
    },
    headerText() {
      if (this.typeId === 0) {
        return {
          en: "all",
          cn: "全部",
          size: 0
        };
      } else if (this.types.length > 0) {
        const typeInfo = this.types.find(item => item.id === this.typeId);
        return {
          en: typeInfo.name.en,
          cn: typeInfo.name.cn,
          size: 0
        };
      } else {
        return {
          en: "",
          cn: "",
          size: 0
        };
      }
    },
    keyword() {
      return this.typeId === 0 && this.$route.query.keyword
        ? this.$route.query.keyword
        : false;
    }
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    }
  },
  methods: {
    ...mapMutations(["PAGE_LOADING", "PAGE_LOADED"]),
    async init() {
      const that = this;
      that.page = 1;
      that.lists = [];
      that.totalCount = null;
      that.paginationFinished = false;
      that.paginationLoading = false;
      that.PAGE_LOADING();
      that.wxShare();
    },
    loadmore() {
      const that = this;
      const conditions = {
        category_id: that.typeId,
        page: that.page
      };
      if (that.keyword) {
        conditions.keyword = that.keyword;
      }
      that.$request.get("/api/shop/products/", conditions).then(res => {
        const data = res.data;
        const size = data._meta.totalCount;
        that.headerText.size = size;
        that.totalCount = size;
        that.lists.push(...data.list);

        if (that.headerText.size > 0) {
          that.$nextTick(() => {
            imgPreload().then(() => {
              that.PAGE_LOADED();
            });
          });
        } else {
          that.PAGE_LOADED();
        }
        that.paginationLoading = false;
        that.page += 1;
        if (data._meta.pageCount === 0 || that.page > data._meta.pageCount) {
          that.paginationFinished = true;
        }
      });
    },
    wxShare() {
      const that = this;
      that.$nextTick(() => {
        const title = "馥馥小高官方网站专业软装艺术设计机构";
        const desc = "为各领域的软装设计需求提供量身定制的专业服务";
        const link = document.URL;
        const imgUrl = that.$configs.wxShareCommonImg;
        wxtool.init().then(wx => {
          wx.updateAppMessageShareData({
            title,
            desc,
            link,
            imgUrl,
            success() {
              console.log("分享成功");
            }
          });
          wx.updateTimelineShareData({
            title,
            desc,
            link,
            imgUrl,
            success() {
              console.log("分享成功");
            }
          });
        });
      });
    }
  }
};
</script>

<style></style>
